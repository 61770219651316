import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://ajhh5uckgk.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Dbt Labs',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/30bae0f9-11cb-4837-1fef-69fd84fd0600/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#047377',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
